@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800;900&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);
@font-face {
  font-family: 'proxima-nova';
  src: url(/static/media/ProximaNova-Regular.b4f720d9.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'proxima-nova', 'Inter', 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-google {
  width: 100%;
  border: 1px solid #111111 !important;
  border-radius: 4px;
  font-weight: 600;
}
.btn-google .icon {
  position: absolute;
  left: 15px !important;
  top: 13px !important;
}

.ant-form-item-control {
  min-width: auto !important;
}

.ant-card-bordered {
  border: 1px solid transparent !important;
  box-shadow: 0 0 1px rgb(12 26 75 / 24%), 0px 3px 8px -1px rgb(50 50 71 / 5%);
}

.StripeElement {
  display: block;
  max-width: 100%;
  padding: 17px 15px 17px 15px;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  border: 1.5px solid #c9c9c9;
  outline: 0;
  border-radius: 2px;
  background: white;
}
.StripeElement:hover {
  border: 1.5px solid #a0a0a0;
}

.StripeElement:focus,
.StripeElement--focus {
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
  border: 1.5px solid #a0a0a0;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.disable-card {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

form[class='ElementsApp'] {
  padding: 100px !important;
}
iframe {
  margin: auto !important;
}

